import Dexie from "dexie";
export default {
    methods: {
        async clearCache() {
            return new Promise(async (resolve) => {
                let keysStorage = Object.keys(localStorage);
                let notRemove = ['setupId', 'language', 'token', 'country', 'user'];
                for (let key of keysStorage) {
                    if (!notRemove.includes(key)) {
                        localStorage.removeItem(key);
                    }
                }
                this.$store.dispatch('contacts/clearCacheResults');
                this.$store.dispatch('contacts/crearTempResults');
                const bases = [
                    {base: 'Results', table: 'results'},
                    {base: 'gds', table: 'images'},
                    {base: 'map', table: 'screenshots'}
                ]
                for (const base of bases) {
                    await this.dropData(base);
                }
                // await Dexie.delete("gds");
                // await Dexie.delete('Results');
                // await Dexie.delete('map');
                await resolve();
            })
        },
        dropData({base, table}) {
            const db = new Dexie(base);
            db.open().then(async () => {
                try {
                    await db.table(table).clear()
                    await db.close()
                } catch(e) {
                    console.log(e);
                    await db.close()
                }
            }).catch()
        }
    }
}