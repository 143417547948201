<template>
  <div>
    <!-- <h2>
      {{ result.source }}: {{ result.value }}
    </h2>
    <div v-for="(infoResult, index) in result.search_results" :key="index">
      <table class="table is-fullwidth is-bordered is-striped" >
        <thead>
        </thead>
        <tbody>
        <raw-info-block v-for="(param, i) in infoResult.data" :key="i" :dataParam="param" />
        </tbody>
      </table>
      <table class="table">
        <tfoot v-if="infoResult.photos.length">
        <tr>
          <td colspan="2">
            <div class="columns is-flex-wrap-wrap">
              <div class="column" v-for="(photo, j) in infoResult.photos" :key="j">
                <small>{{ photo.date | moment('DD.MM.YYYY, H:mm') }}</small>
                <figure class="image is-128x128">
                  <ImageProfile :payload="photo.image" />
                </figure>
              </div>
            </div>
          </td>
        </tr>
        </tfoot>
      </table>
    </div> -->
  </div>
</template>

<script>
// import RawInfoBlock from "./RawInfoBlock";
// import ImageProfile from "./ImageProfile";
export default {
  name: "RawContact",
  components: {
    // RawInfoBlock,
    // ImageProfile
  },
  props: ['result'],
}
</script>

<style lang="scss">/*
 .table {
   margin-bottom: 5px;
 }
 h2 {
   font-weight: 700;
   margin-bottom: 5px;
 }
*/</style>