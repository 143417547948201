<template>
<div class="raw-result">
  <div class="header">
    <div class="limitBlock">
      <b-field class="count-searches has-text-left settingsFlex" :class="{ 'has-text-danger': searchLimit === 0}"
               v-if="searchLimit !== null">
        <b-icon
            class="limitIcon field"
            :class="{ 'red': searchLimit === 0}"
            icon="check"
            size="is-medium" />
        <small
            class="small"
            v-html="searchLimit < 0 ? $t('search.unlimited_requests') : $tc('search.searchLimit', searchLimit, { count: searchLimit })">
        </small>
      </b-field>
    </div>

    <div class="user settingsFlex">
      <small class="small">
        {{user.login}}
      </small>
    </div>
  </div>
<!--  <div style="width: 30px; height: 30px; background-color: red" @click="logout"></div>-->
  <div class="iconContainer" >
    <div  @click="updateSearch">
      <b-tooltip  class="refresh" :label="this.$t('result.update_result')" position="is-left">
        <b-icon
            class="iconUpdate"
            :class="{ 'red': searchLimit === 0}"
            icon="redo-alt"
            size="is-medium"
        />
      </b-tooltip>
    </div>
  </div>

  <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
  <div class="resultContent" v-if="!error">
    <div v-if="!isLoading">
      <raw-contact  v-for="result in results" :result="result" :key="result.key" />
    </div>
    <raw-skeleton v-else/>
  </div>

  <div class="errors" v-else>
    <p  v-html="$t($options.filters.errorTranslate(errorText))"></p>
  </div>

</div>
</template>

<script>
import {mapState} from 'vuex';
import RawContact from "../components/RawContact";
import RawSkeleton from "../components/RawSkeleton";
import filters from "@/mixins/filters";
import clearCache from "@/mixins/clearCache";

export default {
  name: "RawResult",
  components: {RawSkeleton, RawContact},
  mixins: [filters,clearCache],
  data() {
    return {
      payloadObj: {
        param: this.$route.query.param,
        value: this.$route.query.value,
        service_types: ["online"]
      },
      isLoading: true,
      isFullPage: false,
      searchLimit: localStorage.getItem('searchLimit')
      && localStorage.getItem('searchLimit').toLowerCase() !== 'undefined'
          ? parseInt(localStorage.getItem('searchLimit')) : null,
      user: JSON.parse(localStorage.getItem('user')),
      error: false,
      errorText: ''
    }
  },
  props: {
  },
  computed: {
    ...mapState({
      results: state => state.contacts.results.results,
      searchResults: state => {
        return JSON.parse(JSON.stringify(state.contacts.searchesBuffer))
      },
      resultCache: state => state.contacts.resultsCache
    })
  },
  methods:{
    logout() {
      this.$store.dispatch('auth/logoutAction')
          .then(() => {
            this.clearCache();
            return this.$router.push({name: 'Login'})
          })
    },
    search(){
      let key = this.payloadObj.param+'_'+this.payloadObj.value
      let item;
      if (typeof this.resultCache[key] === 'undefined'){
        let payload = this.payloadObj;
        this.$store.dispatch('contacts/search', {payload}).finally(() => {
          this.checkForErrors(key)
          item = this.searchResults.find(item => item.keyVal === key)
          if (!this.error){
            this.$store.dispatch('contacts/updateResult', item).then(()=>{
              this.isLoading = false;
            })
          }
        });
      } else {
        this.checkForErrors(key)
        item = this.searchResults.find(item => item.keyVal === key)
        this.$store.dispatch('contacts/updateResult', item).then(()=>{
          this.isLoading = false;
        })
      }
    },

    checkForErrors(key){
      if (this.resultCache[key].results[0].error){
        this.error = true;
        this.errorText = this.resultCache[key].results[0].errorName
        this.isLoading = false
      }
    },

    updateSearch(){
      this.isLoading = true;
      let key = this.payloadObj.param+'_'+this.payloadObj.value
      let item = this.searchResults.find(item => item.keyVal === key)
      let payload = this.payloadObj;
      this.$store.dispatch('contacts/search', {payload}).then(() => {
        this.checkForErrors(key)
        if (!this.error){
          this.$store.dispatch('contacts/updateResult', item).then(()=>{
            this.isLoading = false;
          })
        }
      });
    },
  },
  mounted() {
    this.$nextTick(()=>{
      this.search();
    })
  }
}
</script>

<style scoped lang="scss">
.raw-result {
  .header{
    display: flex;
    justify-content: space-between;
    text-align: center;
    height: 3rem;
    background: #055A6B;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 3.25rem;
    position: relative;
    z-index: 30;
    margin-bottom: 20px;
    padding: 0.5em 1.5em;
    .settingsFlex{
      display: flex;
      justify-content: flex-start;
      text-align: start;
    }
    .limitIcon{
      width: 31px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      color: #07ea07;
    }
    .small {
      color: white;
      //line-height: 2rem;
      margin-top: 0.5em;
      font-weight: 600;
      margin-right: 0;
    }
  }
  .iconContainer{
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: flex-end;
  }
  .refresh{
    margin-right: 1rem;
    .iconUpdate{
      cursor: pointer;
    }
  }
  .errors{
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 1rem;
    //margin-left: 1rem;
    p {
      font-weight: 600;
    }
  }
}
</style>