<template>
  <div>
    <!-- <h2 style="margin-bottom: 20px;"><b-skeleton width="40%" :animated="true"></b-skeleton></h2>
    <table class="table is-fullwidth is-bordered is-striped">
      <thead>
      </thead>
      <tbody>
      <tr>
        <td style="width: 150px;">
          <b-skeleton></b-skeleton>
        </td>
        <td>
          <b-skeleton width="80%"></b-skeleton>
        </td>
      </tr>
      <tr>
        <td>
          <b-skeleton></b-skeleton>
        </td>
        <td>
          <b-skeleton width="60%"></b-skeleton>
        </td>
      </tr>
      <tr>
        <td>
          <b-skeleton></b-skeleton>
        </td>
        <td>
          <b-skeleton width="77%"></b-skeleton>
        </td>
      </tr>
      <tr>
        <td>
          <b-skeleton></b-skeleton>
        </td>
        <td>
          <b-skeleton width="22%"></b-skeleton>
        </td>
      </tr>
      </tbody>
      <tfoot >
      <tr>
        <td colspan="2">
          <div class="columns is-flex-wrap-wrap">
            <div class="column">
              <small style="margin-bottom: 15px;"><b-skeleton width="60%"></b-skeleton></small>
              <figure class="image is-128x128">
                <b-skeleton height="128px" width="128px"></b-skeleton>
              </figure>
            </div>
            <div class="column">
              <small style="margin-bottom: 15px;"><b-skeleton width="60%"></b-skeleton></small>
              <figure class="image is-128x128">
                <b-skeleton height="128px" width="128px"></b-skeleton>
              </figure>
            </div>
            <div class="column">
              <small style="margin-bottom: 15px;"><b-skeleton width="60%"></b-skeleton></small>
              <figure class="image is-128x128">
                <b-skeleton height="128px" width="128px"></b-skeleton>
              </figure>
            </div>
            <div class="column">
              <small style="margin-bottom: 15px;"><b-skeleton width="18%"></b-skeleton></small>
              <figure class="image is-128x128">
                <b-skeleton height="128px" width="128px"></b-skeleton>
              </figure>
            </div>
          </div>
        </td>
      </tr>
      </tfoot>
    </table> -->
  </div>
</template>

<script>
export default {
name: "RawSkeleton"
}
</script>

<style lang="scss">/*
  small {
    display: block;
    margin-bottom: 10px;
  }
*/</style>